import React, { useState, useEffect, useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { injectSanitiseHtml } from '../utils'
import ScrollContext from './context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import useWindowSize from './custom-hooks/useWindowSize'
import Chapters from './project-components/chapters'
import Portfolio from './../components/home-components/portfolio'
import { ComponentScroller } from './project-components/chapter-types/scroller-base'

import * as aboutStyles from './about.module.scss'

function shuffle(a) {
    const drawThree = []
    const arrayCopy = [...a]
    const iterator = [1, 2, 3]
    iterator.forEach(() => {
        const randomIndex = Math.floor(Math.random() * arrayCopy.length)
        const randomItem = arrayCopy.splice(randomIndex, 1)[0]
        drawThree.push(randomItem)
    })
    return drawThree
}

function shuffleNew(listElements, repetition) {
    const repIdList = repetition.map(
        (rep) => rep.pics.localFile.childImageSharp.gatsbyImageData.id
    )
    const onlyNonRepeated = listElements.filter(
        (elem) =>
            !repIdList.includes(
                elem.pics.localFile.childImageSharp.gatsbyImageData.id
            )
    )
    return shuffle(onlyNonRepeated)
}

const AboutPage = ({ payload }) => {
    const scrollY = useContext(ScrollContext)
    const size = useWindowSize()

    const vwTOpx = (value) => {
        const result = (size.width * value) / 100
        return result
    }

    const [imageList, setImageList] = useState(
        shuffle(payload.wpPage.about_page.heroImages)
    )
    const [imageListPrev, setImageListPrev] = useState(
        shuffle(payload.wpPage.about_page.heroImages)
    )
    const [renderRotate, setrenderRotate] = useState(false)

    const animatedElements = {
        runOpeningAnimation: {
            id: '',
            effect: effects.ON_OPEN,
        },
        heroImages: {
            id: 'hero-images',
            effect: effects.ON_PIVOT_REACHED,
        },
        wellcomeText: {
            id: 'wellcome-text',
            effect: effects.ON_MID_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    // Hero Image Rotation
    useEffect(() => {
        let interval = null
        interval = setInterval(() => {
            const newShufflePossible = payload.wpPage.about_page.heroImages >= 6
            if (renderRotate) {
                setImageListPrev(
                    newShufflePossible
                        ? shuffleNew(
                              payload.wpPage.about_page.heroImages,
                              imageList
                          )
                        : shuffle(payload.wpPage.about_page.heroImages)
                )
            } else {
                setImageList(
                    newShufflePossible
                        ? shuffleNew(
                              payload.wpPage.about_page.heroImages,
                              imageListPrev
                          )
                        : shuffle(payload.wpPage.about_page.heroImages)
                )
            }
            setrenderRotate(!renderRotate)
        }, 5000)
        return () => clearInterval(interval)
    }, [
        imageList,
        imageListPrev,
        renderRotate,
        payload.wpPage.about_page.heroImages,
    ])

    const previous = {
        opacity: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        // border: '3px solid red',
    }

    const faded = {
        opacity: 0,
        height: '100%',
        transition: 'opacity 2s ease',
    }
    const visible = {
        opacity: 1,
        height: '100%',
        transition: 'opacity 2s ease',
    }

    return (
        <div className={aboutStyles.aboutPage}>
            <div
                style={{
                    backgroundColor:
                        payload.wpPage.about_page.aboutBackgroundColor,
                }}
            >
                <div
                    className={aboutStyles.heroContainer}
                    style={{
                        backgroundColor:
                            payload.wpPage.about_page.aboutBackgroundColor,
                    }}
                >
                    <div
                        className={
                            animationStates.runOpeningAnimation
                                ? aboutStyles.titleHolderOpen
                                : aboutStyles.titleHolder
                        }
                    >
                        <h1
                            style={{
                                color: payload.wpPage.about_page.aboutTextColor,
                            }}
                        >
                            {payload.wpPage.about_page.title}
                        </h1>
                    </div>
                    <div className={aboutStyles.taglineBox}>
                        {injectSanitiseHtml(
                            payload.wpPage.about_page.tagline,
                            'div',
                            {
                                color: payload.wpPage.about_page.aboutTextColor,
                            }
                        )}
                    </div>
                    <div
                        className={
                            animationStates.runOpeningAnimation
                                ? aboutStyles.heroImages
                                : aboutStyles.heroImagesClosed
                        }
                        id={animatedElements.heroImages.id}
                    >
                        {imageList.map((image, index) => {
                            const randomKey = Math.random()
                                .toString(36)
                                .slice(2)
                            return (
                                <div
                                    className={aboutStyles.heroImageContainer}
                                    key={`image-list-${index}`}
                                >
                                    <GatsbyImage
                                        image={
                                            imageListPrev[index].pics.localFile
                                                .childImageSharp.gatsbyImageData
                                        }
                                        key={`${imageListPrev[index].pics.localFile.childImageSharp.gatsbyImageData.id}-${randomKey}`}
                                        style={previous}
                                        loading="eager"
                                    />
                                    <GatsbyImage
                                        image={
                                            image.pics.localFile.childImageSharp
                                                .gatsbyImageData
                                        }
                                        key={`${image.pics.localFile.childImageSharp.gatsbyImageData.id}-${randomKey}`}
                                        style={renderRotate ? visible : faded}
                                        loading="eager"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={aboutStyles.heroImageScroller}>
                    <ComponentScroller
                        partialVisbile
                        itemClass={aboutStyles.itemClass}
                        carouselContainer={aboutStyles.carouselContainer}
                        sliderClass={aboutStyles.sliderClass}
                        scrollContainer={aboutStyles.listBox}
                        centerMode={false}
                        transform={0}
                        itemsOnMobile={1}
                        mobileVisibilityGutter={vwTOpx(10)}
                        beforeChangeIncrement={-10}
                        isInverted
                    >
                        {payload.wpPage.about_page.heroImages.map(
                            (image, index) => (
                                <div
                                    className={aboutStyles.heroImageContainer}
                                    key={`carousel-block-about-${index}`}
                                >
                                    <GatsbyImage
                                        image={
                                            image.pics.localFile.childImageSharp
                                                .gatsbyImageData
                                        }
                                        key={
                                            image.pics.localFile.childImageSharp
                                                .gatsbyImageData.id
                                        }
                                        className={
                                            aboutStyles.scrolledHeroImage
                                        }
                                        draggable={false}
                                        loading="eager"
                                    />
                                </div>
                            )
                        )}
                    </ComponentScroller>
                </div>
            </div>
            <div
                className={aboutStyles.declarationContainer}
                style={{
                    color: payload.wpPage.about_page.textColor,
                    backgroundColor: payload.wpPage.about_page.backgroundColor,
                }}
            >
                <div
                    className={
                        animationStates.wellcomeText
                            ? aboutStyles.wellcomeText
                            : aboutStyles.wellcomeTextClosed
                    }
                    id={animatedElements.wellcomeText.id}
                >
                    <div className={aboutStyles.titleBox}>
                        <h2>Our Process</h2>
                    </div>
                    <div className={aboutStyles.descriptionBox}>
                        <h2>{payload.wpPage.about_page.mainBlockTitle}</h2>
                        {injectSanitiseHtml(
                            payload.wpPage.about_page.mainContentBlock,
                            'div'
                        )}
                    </div>
                </div>
            </div>
            <div className={aboutStyles.contentContainer}>
                <Chapters
                    chapters={payload.wpPage.about_page.chapters}
                    noSeparator
                />
                <Portfolio />
            </div>
        </div>
    )
}

export default AboutPage
