import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import About from '../components/about'
import useAboutData from '../components/custom-hooks/useAboutData'

const AboutPage = () => {
    const payload = useAboutData()
    return (
        <Layout
            color={payload.wpPage.about_page.aboutTextColor}
            backgroundColor={payload.wpPage.about_page.aboutBackgroundColor}
        >
            <SEO title="About" />
            <About payload={payload} />
        </Layout>
    )
}

export default AboutPage
