// extracted by mini-css-extract-plugin
export const serviceContainer = "about-module--service-container--le6fF";
export const animationBox = "about-module--animation-box--1_9dd";
export const animationBoxClosed = "about-module--animation-box-closed--1Zx7X";
export const title = "about-module--title--19ubD";
export const titleMobile = "about-module--title-mobile--3tGDX";
export const imageBox = "about-module--image-box--2byfQ";
export const videoBox = "about-module--video-box--A9kdH";
export const backgroundImage = "about-module--background-image--i0UyI";
export const imageDescription = "about-module--image-description--9IlFW";
export const coloredOverlay = "about-module--colored-overlay--1IqSR";
export const metricsBox = "about-module--metrics-box--qtmDX";
export const metrics = "about-module--metrics--vpfR0";
export const aboutPage = "about-module--about-page--32-TI";
export const heroContainer = "about-module--hero-container--3hGP9";
export const taglineBox = "about-module--tagline-box--3-ddc";
export const titleHolder = "about-module--title-holder--qYFZ2";
export const titleHolderOpen = "about-module--title-holder-open--_KhJi";
export const blinkingImage = "about-module--blinking-image--fU7Um";
export const blinkingImageLock = "about-module--blinking-image-lock--3Y6z7";
export const enterImage = "about-module--enter-image--fU0XE";
export const enterActive = "about-module--enter-active--1quhS";
export const exitImage = "about-module--exit-image--2vNAD";
export const exitActive = "about-module--exit-active--3CiEQ";
export const heroImages = "about-module--hero-images--39S5I";
export const heroImageContainer = "about-module--hero-image-container--2hyF7";
export const heroImagesClosed = "about-module--hero-images-closed--2sDpW";
export const heroImageScroller = "about-module--hero-image-scroller--2zD7p";
export const declarationContainer = "about-module--declaration-container--tuAJH";
export const wellcomeText = "about-module--wellcome-text--2NfqY";
export const wellcomeTextClosed = "about-module--wellcome-text-closed--3owUp";
export const contentContainer = "about-module--content-container--39cLn";
export const titleBox = "about-module--title-box--3yhpM";
export const descriptionBox = "about-module--description-box--3Ssdz";
export const itemClass = "about-module--item-class--21Sm7";
export const scrolledHeroImage = "about-module--scrolled-hero-image--30EpX";
export const carouselContainer = "about-module--carousel-container--3I9En";
export const listBox = "about-module--list-box--2Z1sw";